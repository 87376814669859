import React from "react"
import { OverviewArray } from ".."
import Wrapper from "../wrapper"
import Logo from "./logo"

interface IProps {
  data: OverviewArray
  withText?: boolean
  round?: boolean
}

const Logos: React.FC<IProps> = ({ data, withText, round }) => (
  <Wrapper align={withText ? "flex-start" : "center"}>
    {data.map(item => (
      <Logo key={item?.id} data={item} withText={withText} round={round} />
    ))}
  </Wrapper>
)

export default Logos
