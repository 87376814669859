import Typography, { TypographyProps } from "@material-ui/core/Typography"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import useAvatar from "../../../../hooks/useAvatar"
import { OverviewDataFragment } from "../../../../__generated__/graphql-gatsby"
import Divider from "../divider"

interface IProps {
  data: Maybe<OverviewDataFragment>
  withText?: boolean
  round?: boolean
}

const Link = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 256px;
  margin: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(3)}px`};
  text-decoration: none;
`

interface IHeader extends TypographyProps {
  smallText: boolean
}

const Header = styled(({ smallText, ...props }) => (
  <Typography {...props} />
))<IHeader>`
  margin-top: ${({ theme, smallText }) =>
    smallText ? theme.spacing(2.25) : theme.spacing(2)}px;
  margin-bottom: ${({ smallText }) => (smallText ? `2px` : `0`)};
  width: 100%;
  white-space: nowrap;
  font-weight: 500;
  font-size: ${({ smallText }) => (smallText ? `1rem` : `1.18rem`)};
`

const Small = styled.div<{ bold?: boolean }>`
  display: block;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  margin-bottom: ${({ bold }) => (bold ? -4 : 0)}px;
`

const Img = styled(GatsbyImage)`
  width: 256px;
  height: 256px;
`

const Logo: React.FC<IProps> = ({ data, withText, round }) => {
  if (data?.__typename !== "WpVrijwilliger") return null
  const avatar = useAvatar()
  const { afbeelding, info } = { ...data.vrijwilliger?.vrijwilliger }
  const img = afbeelding?.localFile?.childImageSharp?.gatsbyImageData || avatar
  const url = info?.url?.url || "#"

  return (
    <Link href={url} target={String(info?.url?.target)} rel="noopener">
      <Img
        image={img}
        alt={String(afbeelding?.altText)}
        style={{ borderRadius: round ? 256 : 0, overflow: "hidden" }}
        imgStyle={{ objectFit: round ? "cover" : "contain" }}
      />
      {withText && (
        <>
          {info?.weergaveNaam && (
            <Header
              variant="body1"
              color="primary"
              align="center"
              smallText={info?.weergaveNaam?.length > 23}
            >
              {info.weergaveNaam.slice(0, 32)}
            </Header>
          )}
          {info?.omschrijving || info?.url?.title ? (
            <>
              <Typography
                variant="caption"
                component="div"
                color="secondary"
                align="center"
              >
                <Divider />
                {info.url?.title && (
                  <Small
                    bold
                    dangerouslySetInnerHTML={{ __html: info.url?.title }}
                  />
                )}
                {info.omschrijving && (
                  <Small
                    dangerouslySetInnerHTML={{ __html: info.omschrijving }}
                  />
                )}
              </Typography>
            </>
          ) : null}
        </>
      )}
    </Link>
  )
}

export default Logo
