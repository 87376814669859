import Container, { ContainerProps } from "@material-ui/core/Container"
import React from "react"
import styled from "styled-components"

interface WrapperProps extends ContainerProps {
  align?: "center" | "flex-start" | "flex-end"
}

const Wrapper = styled(({ align, ...props }) => (
  <Container {...props} />
))<WrapperProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: ${({ align }) => (align ? align : "center")};
`

export default Wrapper
